import React from 'react'

import Box from 'ibis-design-system/lib/components/core/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';

import { ServerBoxSideWays, ServerBoxLabel, ServerBoxText } from '../Components'

const ServerUnknown = ({ type }) => {

    return (
        <Box>
            <Flex p="12px">
                <ServerBoxSideWays>{type}</ServerBoxSideWays>
                <Box pl="12px" flex={1}>
                    <Box mb="10px" ml="16px">
                        <ServerBoxLabel>Status:</ServerBoxLabel>
                        <ServerBoxText>No information available</ServerBoxText>
                    </Box>
                </Box>

            </Flex>
        </Box>
    )
}

export default ServerUnknown;