import React from 'react';
import styled from 'styled-components';

const Light = styled.div`
    height: 10px;
    width: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    display: inline-block;
`

const StatusLight = ({ isLoaded, isRunning }) => {

    let color = "none"
    if(isLoaded === false) color = "rgba(255, 255, 255, 0.25)";
    else if(isRunning === true) color = "#B4D300"

    return (
        <Light color={color} />
    );
}

export default StatusLight;