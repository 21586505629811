import React from 'react'

import { getServerState } from './Functions';

import AppDown from './UptimeMessages/AppDown';
import AppRunning from './UptimeMessages/AppRunning';
import AppUnknown from './UptimeMessages/AppUnknown';

const EndpointInfoBlock = ({applications, uptime, selected}) => {

    if(!applications || uptime === null || selected === null) return null;

    const frontend = uptime.frontend.find((f) => f.applicationId === selected.id);
    const backend = uptime.backend.find((f) => f.applicationId === selected.id);

    const isLoaded = frontend !== undefined || backend !== undefined;
    const running = getServerState(backend) === "running" && getServerState(frontend) === "running";

    if(isLoaded === false) return <AppUnknown app={selected} />
    else if(running === true) return <AppRunning app={selected} backend={backend} frontend={frontend} />
    else return <AppDown app={selected} backend={backend} frontend={frontend} />
}

export default EndpointInfoBlock;