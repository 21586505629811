import React from 'react';

import Flex from 'ibis-design-system/lib/components/core/Flex';
import Box from 'ibis-design-system/lib/components/core/Box';

import ApplicationTile from './ApplicationTile';
import StatusLight from './StatusLight'
import { Title } from './Components';

const Endpoints = ({ applications, uptime, onAppClick, selectedApp }) => {
    if (applications === null) return <></>;

    const uptimeInfo = applications.map((app) => {

        if (!uptime || uptime.length === 0) return app;

        let info = { ...app };
        info.frontend = uptime.frontend.find((f) => f.applicationId === app.id);
        info.backend = uptime.backend.find((f) => f.applicationId === app.id);
        return info;
    });

    const large = uptimeInfo.filter((a) => a.displaySize === 2);
    const medium = uptimeInfo.filter((a) => a.displaySize !== 2);

    return (
        <Box>
            <Title>Endpoints</Title>
            <Flex flex={1} flexWrap="wrap">{
                large.map(app =>
                    <Application selected={app.id === selectedApp?.id} onAppClick={onAppClick} key={app.id} size="large" app={app} />)
                }
            </Flex>
            <Flex flex={1} flexWrap="wrap">{
                medium.map(app =>
                    <Application selected={app.id === selectedApp?.id} onAppClick={onAppClick} key={app.id} size="medium" app={app} />)
                }
            </Flex>
        </Box>
    );
}

const Application = ({ app, selected, size, onAppClick }) => {

    const isLoaded = app?.frontend !== null && app?.frontend !== undefined && app?.backend !== null && app?.backend !== undefined;
    const isRunning = app?.frontend?.isRunning === true && app?.backend?.isRunning === true;
    const hasError = isLoaded === true && isRunning === false;

    return (
        <Box position="relative" mr={1} mb={1}>
            <div onClick={() => onAppClick(app)}>
                <AppBox selected={selected} app={app} size={size} hasError={hasError} />
            </div>
            <Box position="absolute" top={"10px"} left={"10px"}>
                <StatusLight isLoaded={isLoaded} isRunning={isRunning} />
            </Box>
        </Box>
    );
}

const AppBox = ({ app,selected,  hasError, size }) => {
    const variant = (hasError === true) ? "error" : "regular";
    return (
        <ApplicationTile selected={selected} variant={variant} size={size} icon={app.image}>
            {app.displayName}
        </ApplicationTile>
    );
}

export default Endpoints;