import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import { ReactComponent as Logo } from 'ibis-design-system/lib/Images/AppIcons/logo-ibis.svg';
import Box from 'ibis-design-system/lib/components/core/Box';

import Settings from '../Settings';
import { Background, ViewBase } from './Components'
import Endpoints from './Endpoints'
import Sessions from './Sessions'
import MessagePanel from './MessagePanel'

import backgroundLogo from './../Assets/logo-background.svg';

const ScrollPane = styled.div`
    overflow:auto;
    height:100%;
`;


const Container = ({ applications }) => {

    const [uptime, setUptime] = useState(null);
    const [selectedApp, setSelectedApp] = useState(null);
    const [sessions, setSessions] = useState(null);

    const [shouldUpdate, setShouldUpdate] = useState(true);

    useEffect(() => {
        const end = moment().format("YYYY-MM-DD");
        const start = moment().subtract(1, 'years').format("YYYY-MM-DD");
        const fetchSessionData = () => {
            let url = `${Settings.apiUrls.logService}/visits/daily?start=${start}&end=${end}`;
            if(selectedApp !== null) url += `&application=${selectedApp.id}`;
            HttpGet(url)
                .then(response => {
                    response.data.reverse();
                    setSessions(response);
                    setShouldUpdate(false);
                })
                .catch()
                .finally();
        }
        fetchSessionData();
    }, [shouldUpdate, selectedApp]);

    useEffect(() => {
        const end = moment().startOf("day").add(1,"day");
        const diff = end.diff(moment(), "seconds")  + 1
        const timeOut = setTimeout(() => setShouldUpdate(true) , diff * 1000);
        return () => clearTimeout(timeOut);
    },[shouldUpdate])

    useEffect(() => {
        const fetchUptime = () => {
            HttpGet(`${Settings.apiUrls.logService}/logs/uptime/current`)
                .then(response => { setUptime(response); })
                .catch()
                .finally();
        }
        fetchUptime();
        let interval = setInterval(fetchUptime, 60000);
        return () => clearInterval(interval);
    }, []);

    const onAppClick = (app) => {
        if (selectedApp === null) setSelectedApp(app);
        else if (selectedApp.id === app.id) setSelectedApp(null);
        else setSelectedApp(app);
    }

    return (
        <Background>
            <ImageBackground>
                <ScrollPane>
                    <ViewBase>
                        <Box mr="440px">
                            <Box pt={"60px"}>
                                <Logo />
                            </Box>
                            <Box pt={"18px"}>
                                <Endpoints selectedApp={selectedApp} onAppClick={onAppClick} uptime={uptime} applications={applications} />
                            </Box>
                        </Box>
                        <Box pt={"100px"} pb={"40px"}>
                            <Sessions sessions={sessions} />
                        </Box>
                    </ViewBase>
                </ScrollPane>
                <MessagePanel uptime={uptime} applications={applications} app={selectedApp} />
            </ImageBackground>
        </Background>
    );
}

const ImageBackground = ({ children }) => {
    return <Box
        height="100%"
        width="100%"
        style={{
            backgroundImage: `url(${backgroundLogo})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: '700px'
        }}
    >
        {children}
    </Box>
}

export default Container;
