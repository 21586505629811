import React, { useState, useEffect } from 'react';

import Layout from 'ibis-design-system/lib/components/core/Layout';
import Header from 'ibis-design-system/lib/components/core/Header';
import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import MonitorContainer from './Monitor/Container';
import Settings from './Settings';

function App() {

    const [applications, setApplications] = useState([]);

    useEffect(() => {

        const fetchApplications = () => {
            HttpGet(`${Settings.apiUrls.portal}/applications`)
                .then(response => {setApplications(response); })
                .catch()
                .finally();
        }

        fetchApplications();

    }, []);


    return (
        <Layout>
            <Layout.Header>
                <Header applications={applications} applicationName={Settings.title} />
            </Layout.Header>
            <Layout.Detailview>
                <MonitorContainer applications={applications} />
            </Layout.Detailview>
        </Layout>
    );
}

export default App;
