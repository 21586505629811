import React from 'react';
import moment from 'moment'

import Box from 'ibis-design-system/lib/components/core/Box';

import AppInfoBlock from './UptimeMessages/AppInfoBlock'

const UptimeMessage = ({message}) => {
    const text = (message.type === "backend") ? "Back-end not responding" : "Front-end not responding";
    const logDate = `Last check: ${moment(message.logDate).format("MMM Do, YYYY HH:mm")}`;
    return (
        <Box bg="#FF3300" mb={"1px"} height="88px">
            <AppInfoBlock app={message.application} meta={logDate} text={text} />
        </Box>
    );
};

export default UptimeMessage;