import React from 'react';

import styled from 'styled-components'

export const Background = styled.div`
    background-color : black;
    height:calc(100% - 1px);
    width:100%;
    overflow:hidden;
`

export const Title = styled.h1`
    font-weight: ${props => props.theme.fontWeights.regular};
    color:white;
    margin-bottom:0;
`

export const ViewBase = styled.div`
    padding: 0 100px;
    min-height:100%;
`

export const MonthBase  = styled.div`
    background-color: ${props => props.variant === "odd" ? `rgba(0, 139, 191, 0.15)` : `rgba(0, 139, 191, 0.25)` };
    height:160px;
`

export const Date = styled.div`
    text-align: left;
    font-size: 12px;
    letter-spacing: 0;
    color: #FFFFFF;
    margin: 8px 2px 4px 1px;
    white-space: nowrap;
`

const BarBase = styled.div`
    margin: 0 1px;
    background-color: #0EBBF0;
    width:4px;
`

export const Bar = ({height}) => {
    return <BarBase style={{height : height + "px"}} />
}

export const Divider = styled.div`
    border-left: 10px solid ${(props) => props.color};
    border-right: 10px solid ${(props) => props.color};
    background-color:black;
    width:100%;
    height: 1px;
    box-sizing: border-box;
`

export const ServerBoxSideWays = styled.div`
    writing-mode: vertical-rl;
    text-orientation: sideways;
    color: black;
    font-weight:500;
    font-size: 18px;
    padding-top:8px;
    width:24px;
    white-space: nowrap;
`

export const ServerBoxText  = styled.span`
    font-weight:500;
    font-size: 22px;
    color: black;
`

export const ServerBoxMeta = styled.span`
    font-weight:500;
    font-size: 12px;
    color: black;
    padding-left:4px;
`

export  const ServerBoxLabel  = styled.div`
    font-weight:500;
    font-size: 12px;
    color: black;
`