import moment from 'moment';

export const getServerState = (server) => {
    if(server === undefined || server == null) return "unknown";
    if(server.isRunning === true) return "running";
    return "down";
}

export const getRunningSince = (backend) => {
    if(backend === null || backend === undefined) return null;
    return (backend?.runningSince != null) ? moment(backend?.runningSince) : null;
}