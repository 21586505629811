import React from 'react';
import styled from 'styled-components'
import moment from 'moment';

import Box from 'ibis-design-system/lib/components/core/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';

import { Title, Bar, MonthBase, Date } from './Components';

const GraphHeight = 160;

const Overlay = styled.div`
    position:absolute;
    top:0;
    z-index: 2;
    background: linear-gradient(0.25turn,  rgba(0,0,0,1), rgba(0,0,0,0));
    height:${GraphHeight + 30}px;
    max-width:300px;
    width: 100%;
`

const Data = styled.div`
    position:absolute;
    z-index: 1;
    top:0;
    overflow:hidden;
    width: 100%;
`

const AverageLine = styled.div`
    position:absolute;
    z-index: 3;
    top:30px;
    overflow:hidden;
    width: 100%;
    height:${props => props.place}px;
    border-bottom: 1px solid white;
`

const AverageBlock = styled.div`
    position:absolute;
    z-index: 4;
    top:calc(18px + ${props => props.place}px );
    overflow:hidden;
    height:24px;
    display:flex;
`

const AverageValue = styled.div`
    height:24px;
    padding:2px 8px;
    line-height:20px;
    font-size:16px;
    background-color:white;
    font-weight:500;
`

const AverageArrow = styled.div`
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid white;
`

const Sessions = ({ sessions }) => {
    if(sessions === null) return null;
    return (
        <Box>
            <Title>Sessions per day</Title>
            <Box position="relative" height="190px" >
                <Data>
                    <Flex flexDirection="row-reverse" style={{ overflow: "hidden" }}>
                        {sessions.data.map((m, i) => <Month key={i} max={sessions.max} data={m} />)}
                    </Flex>
                </Data>
                <Overlay />
                <AverageIndicator max={sessions.max} average={sessions.average}  />
            </Box>
        </Box>
    );
}


const Month = ({ data, max }) => {
    const date = moment(`${data.year}-${data.month}`, "YYYY-M").format("MMMM YYYY");
    const variant = (data.month % 2 === 0) ? "even" : "odd";
    return (
        <div>
            <Date>{date}</Date>
            <MonthBase variant={variant}>
                <Flex alignItems="flex-end" height={GraphHeight + "px"}>
                    {data.users.map((u, i) => {
                        const height = (max > 0) ? Math.round((u.users / max) * GraphHeight) : 0;
                        return <Bar key={i} height={height} />
                    })}
                </Flex>
            </MonthBase>
        </div>
    );
}

const AverageIndicator = ({average, max}) => {
    const place =  (max > 0) ? GraphHeight - Math.round((average / max) * GraphHeight) : GraphHeight;

    return <>
        <AverageLine place={place} />
        <AverageBlock place={place}>
            <AverageValue>{Math.round(average)}</AverageValue>
            <AverageArrow />
        </AverageBlock>
    </>;
}


export default Sessions;