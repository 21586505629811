import React from 'react'
import moment from 'moment';

import Box from 'ibis-design-system/lib/components/core/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';

import StatusLight from '../StatusLight'
import { ServerBoxLabel, ServerBoxMeta, ServerBoxSideWays, ServerBoxText } from './../Components';

const FrontendRunning = ({frontend}) => {

    if(frontend === null || frontend === undefined) return null;

    const date = moment(frontend.logDate).format("MMM Do, YYYY HH:mm");
    return (
        <Box>
            <Flex p="12px">
                <ServerBoxSideWays>front-end</ServerBoxSideWays>
                <Box pl="12px" flex={1}>
                    <Box mb="10px" ml="16px">
                        <ServerBoxLabel>Last check:</ServerBoxLabel>
                        <ServerBoxText>{date}</ServerBoxText>
                    </Box>
                    <Flex>
                        <Box width="16px" mt="20px">
                            <StatusLight isLoaded={true} isRunning={true} />
                        </Box>
                        <Box flex={1}>
                            <ServerBoxLabel>Status code:</ServerBoxLabel>
                            <ServerBoxText>{frontend.statusCode}</ServerBoxText>
                        </Box>
                        <Box flex={1}>
                            <ServerBoxLabel>Response time:</ServerBoxLabel>
                            <ServerBoxText>{frontend.responseTime}</ServerBoxText>
                            <ServerBoxMeta>msec</ServerBoxMeta>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    )
}

export default FrontendRunning;