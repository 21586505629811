import React from 'react'

import Box from 'ibis-design-system/lib/components/core/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';

import ServerDown from './ServerDown'
import { ServerBoxSideWays } from '../Components'

const BackendDown = ({backend}) => {

    if(backend === null || backend === undefined) return null;
    return (
        <Box>
            <Flex p="12px">
                <ServerBoxSideWays>back-end</ServerBoxSideWays>
                <ServerDown date={backend.logDate} />
            </Flex>
        </Box>
    )
}

export default BackendDown;