import React from 'react';
import styled from 'styled-components';

import UptimeMessage from './UptimeMessage';
import EndpointInfoBlock from './EndpointInfoBlock';

const Panel = styled.div`
    position: absolute;
    z-index: 10;
    width: 460px;
    top:50px;
    right:50px;
`;

const getUptimeMessages = (uptime, applications, selected) => {
    if(uptime === null || uptime.length === 0) return [];

    const frontend = uptime.frontend.filter((f) => f.isRunning === false);
    const backend = uptime.backend.filter((f) => f.isRunning === false);

    let issues = backend
        .map((f) => { return {...f, ...{type : "backend"}} })
        .concat(frontend.map((f) => { return {...f, ...{type : "fronted"}} }))
        .sort((a,b) => a.applicationId.localeCompare(b.applicationId));


    issues.forEach((issue) => {
        issue.application = applications.find((a) => issue.applicationId === a.id);
    });

    issues = issues.filter((i) => i.application !== null && i.application !== undefined);
    issues = issues.filter((i) => selected == null || selected === undefined || i.applicationId !== selected.id);
    return issues;
}

const MessagePanel = ({uptime,app, applications}) => {
    const uptimeMessage = getUptimeMessages(uptime, applications, app);
    return (
        <Panel>
            <EndpointInfoBlock uptime={uptime} applications={applications} selected={app} />
            {uptimeMessage.map((message,i) => <UptimeMessage key={i} message={message} />)}
        </Panel>
    );
}


export default MessagePanel
