import React from 'react'
import moment from 'moment';

import Box from 'ibis-design-system/lib/components/core/Box';

import AppInfoBlock from './AppInfoBlock'
import RunningFrontend from './FrontendRunning'
import BackendRunning from './BackendRunning'
import { Divider } from '../Components';

import { getRunningSince } from '../Functions';

const AppRunning = ({app, backend, frontend}) => {

    const since = getRunningSince(backend);
    const meta  = `Since ${moment(since).format("MMM Do, YYYY HH:mm")}`;
    return (
        <Box bg="#AAAAAA" mb="1px">
            <AppInfoBlock app={app} text="Running" meta={meta} />
            <Divider color="#AAAAAA" />
            <RunningFrontend frontend={frontend} />
            <Divider color="#AAAAAA" />
            <BackendRunning backend={backend} />
        </Box>
    )
}

export default AppRunning;