import React from 'react'
import moment from 'moment';
import styled from 'styled-components';

import Box from 'ibis-design-system/lib/components/core/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';

import StatusLight from '../StatusLight'

const SideWays = styled.div`
    writing-mode: vertical-rl;
    text-orientation: sideways;
    color: black;
    font-weight:500;
    font-size: 18px;
    padding-top:8px;
    width:24px;
    height: 106px;
`

const Text  = styled.span`
    font-weight:500;
    font-size: 22px;
    color: black;
`

const Meta = styled.span`
    font-weight:500;
    font-size: 12px;
    color: black;
    padding-left:4px;
`

const Label  = styled.div`
    font-weight:500;
    font-size: 12px;
    color: black;
`

const BackendRunning = ({backend}) => {

    if(backend === null || backend === undefined) return null;

    const date = moment(backend.runningSince).format("MMM Do, YYYY HH:mm");
    return (
        <Box>
            <Flex p="12px">
                <SideWays>back-end</SideWays>
                <Box pl="12px" flex={1}>
                    <Box mb="10px" ml="16px">
                        <Label>Running since:</Label>
                        <Text>{date}</Text>
                    </Box>
                    <Box mb="10px" ml="16px">
                        <Label>Version:</Label>
                        <Text>{backend.applicationVersion} / {backend.sharedLibVersion}</Text>
                    </Box>
                    <Flex>
                        <Box width="16px" mt="20px">
                            <StatusLight isLoaded={true} isRunning={true} />
                        </Box>
                        <Box flex={1}>
                            <Label>Status code:</Label>
                            <Text>200</Text>
                        </Box>
                        <Box flex={1}>
                            <Label>Response time:</Label>
                            <Text>{backend.responseTime}</Text>
                            <Meta>msec</Meta>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    )
}

export default BackendRunning;