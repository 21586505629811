import React from 'react';
import styled from 'styled-components'

import AppLogo from 'ibis-design-system/lib/components/core/AppLogo';
import Box from 'ibis-design-system/lib/components/core/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';

const AppName = styled.div`
    font-size: 12px;
    font-weight: 500;
    width:328px;
    overflow:hidden;
    color:black;
`

const Text = styled.div`
    width:328px;
    overflow:hidden;
    font-size: 22px;
    font-weight: 500;
    color:black;
`

const MetaInfo = styled.div`
    font-size: 14px;
    font-weight: 500;
    width:328px;
    overflow:hidden;
    color:black;
`

const AppInfoBlock = ({app, meta, text}) => {
    return (
        <Flex height="88px">
            <Box p={"11px"} >
                <AppLogo width="66px" height="66px" name={app.image} />
            </Box>
            <Flex pt={"11px"} flexDirection="column">
                <AppName>{app.displayName}</AppName>
                <Text>{text}</Text>
                <MetaInfo>{meta}</MetaInfo>
            </Flex>
        </Flex>
    );
};

export default AppInfoBlock;