import React from 'react';
import ReactDOM from 'react-dom';
import ThemeProvider from 'ibis-design-system/lib/ThemeProvider';
import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import App from './App';
import Settings, { SetSettings } from './Settings';
import * as serviceWorker from './serviceWorker';

let settingsPath = 'appSettings.json';
if (process.env.NODE_ENV === 'development') settingsPath = 'appSettings.dev.json';

HttpGet(`${window.location.origin}/${settingsPath}`).then(response => {
    SetSettings(response);

    ReactDOM.render(
        <ThemeProvider>
            <App />
        </ThemeProvider>,
        document.getElementById('root')
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
