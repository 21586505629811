import React from 'react'

import Box from 'ibis-design-system/lib/components/core/Box';

import AppInfoBlock from './AppInfoBlock'

const AppUnknown = ({app}) => {
    return (
        <Box bg="rgba(255,255,255,0.4)" mb="1px">
            <AppInfoBlock app={app} text="Status unknown" meta="" />
        </Box>
    );
}

export default AppUnknown;