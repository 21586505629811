
import React from 'react'
import moment from 'moment';

import Box from 'ibis-design-system/lib/components/core/Box';

import AppInfoBlock from './AppInfoBlock'
import BackendDown from './BackendDown'
import BackendRunning from './BackendRunning'
import FrontendDown from './FrontendDown';
import FrontendRunning from './FrontendRunning'
import ServerUnknown from './ServerUnknown'

import { Divider } from '../Components';
import { getServerState, getRunningSince } from '../Functions';


const AppDown = ({app, backend, frontend}) => {

    let text = ""; let meta = "";

    const frontendState = getServerState(frontend);
    const backendState = getServerState(backend);
    const since = getRunningSince(backend);
    const state = frontendState + "-" + backendState;

    switch(state) {
        case "unknown-down" :
            case "down-down" :
            text = "Front- and back-end down";
            meta = `Last back-end check: ${moment(backend.logDate).format("MMM Do, YYYY HH:mm")}`;
            break;
        case "unknown-running" :
        case "down-running" :
            text = "Front-end not responding"
            meta = `Back-end up: Since ${moment(since).format("MMM Do, YYYY HH:mm")}`;
            break;
        case "down-unknown" :
            text = "Front- and back-end down";
            meta = `Last front-end check: ${moment(frontend.logDate).format("MMM Do, YYYY HH:mm")}`;
            break;
        case "running-unknown" :
        case "running-down" :
            text = "Back-end not responding"
            meta = `Last front-end check: ${moment(frontend.logDate).format("MMM Do, YYYY HH:mm")}`;
            break;
        case "running-running" :
            text = "Running"
            meta = `Back-end up: Since ${moment(since).format("MMM Do, YYYY HH:mm")}`;
            break;
        default :
            text = "Front- and back-end down";
            meta = "last check: unknown"
            break;
    }

    return (
        <Box bg="#FF3300" mb="1px">
            <AppInfoBlock app={app} text={text} meta={meta} />
            <Divider color="#FF3300" />
            <Fronted app={app} frontend={frontend}  />
            <Divider color="#FF3300" />
            <Backend app={app} backend={backend}  />
        </Box>
    );
};

const Fronted = ({frontend}) => {
    const state = getServerState(frontend);
    switch(state) {
        case "down" : return <FrontendDown frontend={frontend} />;
        case "running" : return <FrontendRunning frontend={frontend} />
        default: return <ServerUnknown type="front-end" />
    }
}

const Backend = ({backend}) => {

    const state = getServerState(backend);
    switch(state) {
        case "down" : return <BackendDown backend={backend} />;
        case "running" : return <BackendRunning backend={backend} />;
        default: return <ServerUnknown type="back-end" />
    }
}

export default AppDown;