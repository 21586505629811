
import React from 'react'
import moment from 'moment';

import Box from 'ibis-design-system/lib/components/core/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';

import StatusLight from '../StatusLight'
import { ServerBoxLabel, ServerBoxMeta, ServerBoxText } from '../Components';

const ServerDown = ({ date }) => {
    return (
        <Box pl="12px" flex={1}>
            <Box mb="10px" ml="16px">
                <ServerBoxLabel>last checked:</ServerBoxLabel>
                <ServerBoxText>{moment(date).format("MMM Do, YYYY HH:mm")}</ServerBoxText>
            </Box>
            <Flex>
                <Box width="16px" mt="20px">
                    <StatusLight isLoaded={true} isRunning={false} />
                </Box>
                <Box flex={1}>
                    <ServerBoxLabel>Status code:</ServerBoxLabel>
                    <ServerBoxText>-</ServerBoxText>
                </Box>
                <Box flex={1}>
                    <ServerBoxLabel>Response time:</ServerBoxLabel>
                    <ServerBoxText>&infin;</ServerBoxText>
                    <ServerBoxMeta>msec</ServerBoxMeta>
                </Box>
            </Flex>
        </Box>
    )
}

export default ServerDown;